<template>
  <v-btn
    v-if="createEdit"
    icon
    color="grey darken-3"
    class="mr-2"
    @click="edit"
  >
    <v-icon>
      fa fa-edit
    </v-icon>
  </v-btn>
</template>

<script lang="babel" type="text/babel">
import treeNodeAction from '@/components/tree/nodeAction/treeNodeAction'
export default {
  mixins: [treeNodeAction],
  methods: {
    edit() {
      this.$store.dispatch(`tree/${this.treeKey}/set`, {
        key: 'createActionsBar',
        value: false,
      })

      const formKey = `${this.treeKey}-node-form`
      this.$apopup.base({
        title: this.$t(`action.edit`),
        treeKey: this.treeKey,
        node: window.eagleLodash.cloneDeep(this.item),
        width: '80vw',
        formKey,
        actionProps: { formKey },
        bodySlot: () => import('@/components/tree/nodeAction/treeNodeEditPopup/treeNodeEditPopup.vue'),
        actionSlot: () => import('@/components/tree/nodeAction/treeNodeEditPopup/treeNodeEditPopupActions.vue'),
        closePromise: data => {
          if(!data.hasChanged) return true
          if(confirm(this.$t('exit.confirm'))) {
            return true
          }
          return false
        },
        closeCallback: () => {
          this.$store.dispatch(`tree/${this.treeKey}/set`, {
            key: 'createActionsBar',
            value: true,
          })
        }
      })
    },
  },
  computed: {
    createEdit() {
      if(!this.hasWritePermission) return false
      if(!this.treeConfigActions) return true
      if(typeof this.treeConfigActions.edit != 'function') return true
      return this.treeConfigActions.edit(this.item, this.item.depth)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
